import React, {
  useState,
  useEffect,
  Suspense,
  useRef,
  useContext,
} from "react";
import { color } from "../../theme/Color";
import PropTypes from "prop-types";
import * as Styled from "./style";
import { Grid, Box, Typography, Stack } from "@mui/material";
import "./BasicTabs.css";
import FilterBar from "../FilterBar/FilterBar";
import { screenSizes, constants } from "../../constants/Constants";
import useCustomMediaQuery from "../../utils/useCustomMediaQuery";
import { ThemeContext } from "../../context/ThemeContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Styled.TabsDiv
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ marginRight: "0.5%" }}>{children}</Box>}
    </Styled.TabsDiv>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs({ tabArray, product, model, sector }) {
  const [value, setValue] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);

  const isMobile = useCustomMediaQuery(screenSizes.mobile);
  const isTablet = useCustomMediaQuery(screenSizes.tablet);
  const { mode } = useContext(ThemeContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const prevProductRef = useRef(null);
  useEffect(() => {
    const options = tabArray.map((tab, index) => ({
      value: index,
      label: tab.label,
    }));
    if (product) {
      if (
        (isMobile || isTablet) &&
        (!selectedOption || selectedOption.value >= options.length)
      ) {
        setSelectedOption(options[0]);
      } else if (product !== prevProductRef.current?.product) {
        setValue(0);
      } else if (model !== prevProductRef.current?.model) {
        setValue(0);
      } else if (
        product?.productId === prevProductRef.current?.product?.productId &&
        prevProductRef.current?.sector !== undefined &&
        sector !== prevProductRef.current?.sector
      ) {
        setValue(0);
      }
    }
    prevProductRef.current = { product, model };
  }, [tabArray, product, sector, selectedOption]);

  return (
    <Styled.GridContainer
      container
      flexDirection={"column"}
      data-accessible={true}
      data-testid="grid-container"
      mode={mode}
    >
      <Stack
        direction={"column"}
        className="content"
        sx={{
          overflow: "hidden",
          background: mode === "light" ? color._F3F7FD : color._131313,
        }}
        data-accessible={true}
        data-testid="stack-container"
      >
        {!isMobile && (
          <>
            <Stack id="content-header" direction={"row"}>
              <Stack className="contentTitle">
                {product?.programId === constants?.programs?.HCT
                  ? product?.programName
                  : product?.programFullName}
                {!isTablet && product && (
                  <Typography
                    variant="span"
                    className="content-subtitle"
                    sx={{
                      color: mode === "light" ? color._131313 : color._F3F7FD,
                    }}
                  >
                    {product?.productId === constants?.products?.GuidedSelling
                      ? "GS " + model?.label
                      : product?.productName}
                  </Typography>
                )}
              </Stack>
              <FilterBar hideDropdowns={false} />
            </Stack>
            {isTablet && (
              <Stack
                direction={"row"}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  className="subHeaderContainer"
                  item
                  data-testid="basic-tablet"
                >
                  {product && (
                    <Typography
                      variant="span"
                      className={`subHeader basic-${mode}`}
                    >
                      {product?.productId === constants?.products?.GuidedSelling
                        ? "GS " + model?.label
                        : product?.productName}
                    </Typography>
                  )}
                </Grid>
              </Stack>
            )}
          </>
        )}
        <Grid item>
          {isMobile && (
            <Stack
              id="content-title"
              className={`basic-${mode}`}
              style={{ fontSize: "24px" }}
            >
              {product?.programId === constants?.programs?.HCT
                ? product?.programName
                : product?.programFullName}
            </Stack>
          )}
        </Grid>
        <Grid item>
          <Box>
            {isMobile ? (
              <Stack data-testid="basic-tab-mobile">
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {product && (
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          color: "#D0D0D0",
                        }}
                        className={`basic-${mode}`}
                      >
                        {typeof product === "object"
                          ? product?.productId ===
                            constants?.products?.GuidedSelling
                            ? "GS " + model?.label
                            : product?.productName
                          : product}
                      </Typography>
                    )}
                  </Stack>
                  <FilterBar hideDropdowns={false} />
                </Stack>
                <Grid item>
                  <Styled.TabContainer
                    value={value}
                    onChange={handleChange}
                    inputProps={{
                      autoCapitalize: "none",
                    }}
                    variant="scrollable"
                    scrollButtons={false}
                    mode={mode}
                  >
                    {tabArray?.map((tab) => (
                      <Styled.TabItems
                        key={tab.id}
                        label={tab.label}
                        mode={mode}
                      />
                    ))}
                  </Styled.TabContainer>
                </Grid>
              </Stack>
            ) : (
              <Stack data-testid="basic-tab-tablet">
                <Styled.TabContainer
                  value={value}
                  onChange={handleChange}
                  inputProps={{
                    autoCapitalize: "none",
                  }}
                  wrapped={true}
                  variant="scrollable"
                  scrollButtons={false}
                  mode={mode}
                >
                  {tabArray?.map((tab) => (
                    <Styled.TabItems
                      key={tab.id}
                      label={tab.label}
                      mode={mode}
                    />
                  ))}
                </Styled.TabContainer>
              </Stack>
            )}
            <Suspense>
              {tabArray?.map((tab, index) => (
                <TabPanel key={index} value={value} index={index}>
                  <tab.component />
                </TabPanel>
              ))}
            </Suspense>
          </Box>
        </Grid>
      </Stack>
    </Styled.GridContainer>
  );
}
